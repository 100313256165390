<template>
  <div class="details content-index" v-loading="loading">
    <el-page-header @back="goBack" style="margin:10px 0 20px">
      <template slot="content">
        <div class="title">财务月度对账单</div>
      </template>
    </el-page-header>
    <!-- 账单标题 -->
    <div class="details-title">
      {{ datailsData.storeName + datailsData.billMonth }}月份财务对账单
    </div>
    <div class="details-text">账单进度：{{ datailsData.billResultName }}</div>
    <!-- 账单统计 -->
    <el-row type="flex" justify="space-around" style="padding:0 200px">
      <el-col :span="6">
        <el-card class="box-card" shadow="never">
          <el-row class="card-title">收入合计（元）</el-row>
          <el-row class="card-price">{{
            datailsData.totalIncome ? datailsData.totalIncome : 0
          }}</el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card" shadow="never">
          <el-row class="card-title"> 支出合计（元）</el-row>
          <el-row class="card-price">{{
            datailsData.totalExpenditure ? datailsData.totalExpenditure : 0
          }}</el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card" shadow="never">
          <el-row class="card-title">收/支 利差（元）</el-row>
          <el-row class="card-price">{{ datailsData.revenueSpread }}</el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 收入项目列表 -->
    <el-row class="revenue-list">
      <el-row class="top">
        收入项目列表
        <el-button type="text" @click="lookDetails(1)">查看明细</el-button>
      </el-row>
      <el-row class="content">
        <el-row
          type="flex"
          style="margin-bottom:10px"
          justify="space-between"
          v-for="(item, index) in datailsData.incomeList"
          :key="index"
        >
          <el-col :span="4" class="text">{{ item.projectName }}：</el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1">{{ item.oneRowName }}</span>
              <span class="text2"
                >{{ item.oneRowValue ? item.oneRowValue : 0
                }}{{ item.oneRowUnit }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1">{{ item.twoRowName }}</span>
              <span class="text2"
                >{{ item.twoRowValue ? item.twoRowValue : 0
                }}{{ item.twoRowUnit }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1">{{ item.threeRowName }}</span>
              <span class="text2"
                >{{ item.threeRowValue ? item.threeRowValue : 0
                }}{{ item.threeRowUnit }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1">{{ item.formalitiesFeeName }}</span>
              <span class="text2"
                >{{ item.formalitiesFeeValue ? item.formalitiesFeeValue : 0
                }}{{ item.formalitiesFeeUnit }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1"
                >{{ item.fourRowName }}
                <el-popover
                  placement="bottom"
                  trigger="click"
                  v-if="
                    item.projectName == '权益卡' ||
                      item.projectName == '美团订单' ||
                      item.projectName == '小程序支付订单'
                  "
                >
                  <div>结算金额：消费金额*结算比例*手续费=结算金额</div>
                  <i
                    slot="reference"
                    class="el-icon-question"
                    style="color:#000;margin-left:5px"
                  ></i>
                </el-popover>
              </span>
              <span class="text2"
                >{{ item.fourRowValue ? item.fourRowValue : 0
                }}{{ item.fourRowUnit }}</span
              >
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" style="margin-bottom:10px" justify="space-between">
          <el-col :span="4" class="text">财务说明：</el-col>
          <el-col :span="20">
            <div class="remark">
               <el-input
                v-model="datailsData.incomeIllustrate"
                :disabled="true"
                maxlength="300"
                placeholder="无内容"
                :rows="3"
                resize="none"
                show-word-limit
                type="textarea"
              />
            </div>
          </el-col>
        </el-row>
      </el-row>
    </el-row>
    <!-- 支出项目列表 -->
    <el-row class="expenditure-list">
      <el-row class="top">
        支出项目列表
        <el-button type="text" @click="lookDetails(2)">查看明细</el-button>
      </el-row>
      <el-row class="content">
        <el-row
          type="flex"
          style="margin-bottom:10px"
          justify="space-between"
          v-for="(item, index) in datailsData.expendList"
          :key="index"
        >
          <el-col :span="4" class="text">{{ item.projectName }}：</el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1">{{ item.oneRowName }}</span>
              <span class="text2"
                >{{ item.oneRowValue ? item.oneRowValue : 0
                }}{{ item.oneRowUnit }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1">{{ item.twoRowName }}</span>
              <span class="text2"
                >{{ item.twoRowValue ? item.twoRowValue : 0
                }}{{ item.twoRowUnit }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1"
                >{{ item.threeRowName }}
                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="click"
                  v-if="item.projectName == '洗涤费支出'"
                >
                  <div>
                    权益免洗：统计当前门店在当前选择的月份里使用权益卡赠送的免洗券消费的衣物件数（重点：按当月消费为准，不按照发券时间统计）。
                  </div>
                  <i
                    slot="reference"
                    class="el-icon-question"
                    style="color:#000;margin-left:5px"
                  ></i>
                </el-popover>
              </span>
              <span class="text2"
                >{{ item.threeRowValue ? item.threeRowValue : 0
                }}{{ item.threeRowUnit }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item" style="margin-right:10px">
              <span class="text1">{{ item.formalitiesFeeName }}</span>
              <span class="text2"
                >{{ item.formalitiesFeeValue ? item.formalitiesFeeValue : 0
                }}{{ item.formalitiesFeeUnit }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item">
              <span class="text1">{{ item.fourRowName }}</span>
              <span class="text2"
                >{{ item.fourRowValue ? item.fourRowValue : 0
                }}{{ item.fourRowUnit }}</span
              >
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" style="margin-bottom:10px" justify="space-between">
          <el-col :span="4" class="text">财务说明：</el-col>
          <el-col :span="20">
            <div  class="remark">
              <el-input
                v-model="datailsData.expenditureIllustrate"
                :disabled="true"
                maxlength="300"
                placeholder="无内容"
                :rows="3"
                resize="none"
                show-word-limit
                type="textarea"
              />
              <!-- {{
                datailsData.expenditureIllustrate
                  ? datailsData.expenditureIllustrate
                  : "无"
              }}。 -->
            </div>
          </el-col>
        </el-row>
      </el-row>
    </el-row>
    <!-- 底部操作按钮 -->
    <el-row class="bottom-button">
      <el-button
        type="primary"
        class="button1"
        :disabled="datailsData.billResultName != '未确认'"
        @click="undifferentiated"
        >无差异</el-button
      >
      <el-button
        type="primary"
        class="button2"
        :disabled="datailsData.billResultName != '未确认'"
        @click="variant"
        >有差异</el-button
      >
    </el-row>
    <!-- 有差异弹框 -->
    <el-dialog
      title="本期账单【有异议】"
      :visible.sync="dialogVisible"
      width="600px"
      center
      @close="resetForm"
    >
      <el-form
        :model="differenceForm"
        :rules="rules"
        ref="differenceForm"
        label-width="100px"
        class="differenceForm"
      >
        <el-form-item label="异议说明：" prop="remark">
          <el-input
            type="textarea"
            placeholder="请详细说明你的异议点，便于
财务重新核对..."
            v-model.trim="differenceForm.remark"
            maxlength="300"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="buttonLoading"
          @click="enterDifference"
          >确 定</el-button
        >
        <el-button @click="resetForm">在看看</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getFinancialStatementDetail,
  updateFinancialBillStatus,
} from "@/api/store/accounting.js";
export default {
  components: {},
  data() {
    return {
      financialBillId: null,
      datailsData: {},
      dialogVisible: false,
      differenceForm: {},
      rules: {
        remark: [
          { required: true, message: "请输入你的异议点", trigger: "blur" },
          {
            min: 1,
            max: 300,
            message: "请输入1-300个字的异议描述",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      buttonLoading: false,
    };
  },
  watch: {
    $route: {
      handler: function(value) {
        if (value.query.id) {
          this.financialBillId = value.query.id;
          this.loginBool = value.query.bool;
        } else {
          this.$router.back();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeRouteLeave(to, form, next) {
    if (to.name != "transactionDetail" && to.name != "accounting") {
      this.$confirm("你确定离开该页面吗？", "提示", {
        confirmButtonText: "确定离开",
        cancelButtonText: "我再看看",
        type: "warning",
      })
        .then(() => {
          next();
        })
        .catch(() => {});
    } else {
      next();
    }
  },
  created() {
    this.getFinancialStatementDetailList();
  },
  methods: {
    goBack() {
      console.log(this.loginBool);
      this.$router.push({
        name: "accounting",
        params: {
          bool: this.loginBool,
        },
      });
    },
    // 财务对账详情
    getFinancialStatementDetailList() {
      this.loading = true;
      getFinancialStatementDetail({ financialBillId: this.financialBillId })
        .then(({ data }) => {
          if (data.code == 0) {
            this.datailsData = data.data;
            // datailsData.totalIncome
            //datailsData.totalExpenditure
            //  datailsData.revenueSpread
            this.datailsData.revenueSpread =
              this.datailsData.revenueSpread &&
              this.datailsData.totalIncome >= this.datailsData.totalExpenditure
                ? "+" + this.datailsData.revenueSpread
                : this.datailsData.revenueSpread;
            this.datailsData.incomeList = this.datailsData?.billProjectList?.filter(
              (item) => item.projectType == 1
            );
            this.datailsData.expendList = this.datailsData?.billProjectList?.filter(
              (item) => item.projectType == 2
            );
            this.datailsData.expenditureIllustrate =
              data.data.expenditureIllustrate;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    lookDetails(val) {
      this.$router.push({
        path: "/home/transactionDetail",
        query: {
          status: val,
          id: this.financialBillId,
          bool: this.loginBool,
        },
      });
    },
    // 无差异
    undifferentiated() {
      this.$confirm("本期账单【无异议】", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "在看看",
        type: "warning",
        center: true,
      })
        .then(() => {
          updateFinancialBillStatus({
            financialBillId: this.financialBillId,
            changeFlag: 2,
          }).then(({ data }) => {
            if (data.code == 0) {
              this.getFinancialStatementDetailList();
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 有差异
    variant() {
      this.dialogVisible = true;
    },
    // 确认有差异
    enterDifference() {
      this.$refs.differenceForm.validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          updateFinancialBillStatus({
            financialBillId: this.financialBillId,
            changeFlag: 1,
            remark: this.differenceForm.remark,
          })
            .then(({ data }) => {
              if (data.code == 0) {
                this.getFinancialStatementDetailList();
                this.dialogVisible = false;
                this.buttonLoading = false;
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              } else {
                this.dialogVisible = false;
                this.buttonLoading = false;
              }
            })
            .catch(() => {
              this.dialogVisible = false;
              this.buttonLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 关闭弹框
    resetForm() {
      this.dialogVisible = false;
      this.differenceForm = {};
      this.$refs.differenceForm.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  position: relative;
  padding: 10px 30px 0 30px;
  overflow: auto;

  .title {
    font-size: 20px;
    color: rgba(16, 16, 16, 100);
  }
  .details-title {
    font-size: 20px;
    text-align: center;
  }
  .details-text {
    font-size: 20px;
    padding-top: 5px;
    text-align: center;
  }
  .box-card {
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    margin-top: 25px;
    background-color: #f4f5f7;
    .card-title {
      font-size: 16px;
      padding-bottom: 10px;
    }
    .card-price {
    }
  }
  .revenue-list,
  .expenditure-list {
    margin-top: 30px;
    border-radius: 5px;
    background-color: rgba(244, 245, 247, 100);
    position: relative;
    .top {
      padding: 15px 0;
      text-align: center;
      font-size: 20px;
    }
    .el-button {
      position: absolute;
      top: 10px;
      right: 30px;
    }
    .content {
      padding: 0 20px 10px 0;
      .text {
        font-size: 15px;
        height: 40px;
        line-height: 40px;
        text-align: right;
      }
      .item {
        border-radius: 5px;
        background-color: rgba(253, 253, 253, 100);
        height: 40px;
        display: flex;
        font-size: 15px;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        .text1 {
          color: #9b9b9b;
        }
      }
      .remark {
        border-radius: 5px;
        background-color: rgba(253, 253, 253, 100);
        color: #9b9b9b;
        // padding: 2px 20px;
        // min-height: 70px;
        ::v-deep .el-textarea__inner{
          background-color: #fff;
          border: none;
          color: #9b9b9b;
          font-family: ”Microsoft YaHei”, Arial, Helvetica, sans-serif,”宋体”;
        }
      }
    }
  }
  .expenditure-list {
    margin-bottom: 20px;
  }
  .bottom-button {
    border-radius: 5px;
    background-color: rgba(244, 245, 247, 100);
    text-align: center;
    height: 70px;
    line-height: 70px;
    margin-bottom: 50px;
    .button1 {
      width: 150px;
      height: 45px;
      font-size: 18px;
    }
    .button2 {
      width: 150px;
      height: 45px;
      font-size: 18px;
      background-color: #860404;
      border: none;
    }
  }
  .differenceForm {
    ::v-deep .el-textarea__inner {
      height: 120px;
    }
  }
  ::v-deep .el-dialog__footer {
    padding-top: 0 !important;
  }
}
</style>
